exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-actions-boat-complete-js": () => import("./../../../src/pages/actions/boat/complete.js" /* webpackChunkName: "component---src-pages-actions-boat-complete-js" */),
  "component---src-pages-actions-boat-request-js": () => import("./../../../src/pages/actions/boat/request.js" /* webpackChunkName: "component---src-pages-actions-boat-request-js" */),
  "component---src-pages-actions-car-request-js": () => import("./../../../src/pages/actions/car/request.js" /* webpackChunkName: "component---src-pages-actions-car-request-js" */),
  "component---src-pages-actions-landing-personalised-villas-complete-js": () => import("./../../../src/pages/actions/landing/personalised-villas/complete.js" /* webpackChunkName: "component---src-pages-actions-landing-personalised-villas-complete-js" */),
  "component---src-pages-actions-landing-personalised-villas-request-js": () => import("./../../../src/pages/actions/landing/personalised-villas/request.js" /* webpackChunkName: "component---src-pages-actions-landing-personalised-villas-request-js" */),
  "component---src-pages-actions-service-request-js": () => import("./../../../src/pages/actions/service/request.js" /* webpackChunkName: "component---src-pages-actions-service-request-js" */),
  "component---src-pages-actions-villa-complete-js": () => import("./../../../src/pages/actions/villa/complete.js" /* webpackChunkName: "component---src-pages-actions-villa-complete-js" */),
  "component---src-pages-actions-villa-enquire-js": () => import("./../../../src/pages/actions/villa/enquire.js" /* webpackChunkName: "component---src-pages-actions-villa-enquire-js" */),
  "component---src-pages-actions-villa-request-js": () => import("./../../../src/pages/actions/villa/request.js" /* webpackChunkName: "component---src-pages-actions-villa-request-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-boats-js": () => import("./../../../src/pages/boats.js" /* webpackChunkName: "component---src-pages-boats-js" */),
  "component---src-pages-cars-js": () => import("./../../../src/pages/cars.js" /* webpackChunkName: "component---src-pages-cars-js" */),
  "component---src-pages-guide-js": () => import("./../../../src/pages/guide.js" /* webpackChunkName: "component---src-pages-guide-js" */),
  "component---src-pages-index-2-js": () => import("./../../../src/pages/index-2.js" /* webpackChunkName: "component---src-pages-index-2-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-listings-js": () => import("./../../../src/pages/listings.js" /* webpackChunkName: "component---src-pages-listings-js" */),
  "component---src-pages-lists-js": () => import("./../../../src/pages/lists.js" /* webpackChunkName: "component---src-pages-lists-js" */),
  "component---src-pages-new-way-js": () => import("./../../../src/pages/new-way.js" /* webpackChunkName: "component---src-pages-new-way-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-villa-js": () => import("./../../../src/pages/villa.js" /* webpackChunkName: "component---src-pages-villa-js" */),
  "component---src-pages-villas-js": () => import("./../../../src/pages/villas.js" /* webpackChunkName: "component---src-pages-villas-js" */),
  "component---src-pages-whats-open-js": () => import("./../../../src/pages/whats-open.js" /* webpackChunkName: "component---src-pages-whats-open-js" */),
  "component---src-templates-area-page-js": () => import("./../../../src/templates/areaPage.js" /* webpackChunkName: "component---src-templates-area-page-js" */),
  "component---src-templates-area-with-category-js": () => import("./../../../src/templates/AreaWithCategory.js" /* webpackChunkName: "component---src-templates-area-with-category-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-boat-js": () => import("./../../../src/templates/boat.js" /* webpackChunkName: "component---src-templates-boat-js" */),
  "component---src-templates-car-js": () => import("./../../../src/templates/car.js" /* webpackChunkName: "component---src-templates-car-js" */),
  "component---src-templates-category-page-js": () => import("./../../../src/templates/categoryPage.js" /* webpackChunkName: "component---src-templates-category-page-js" */),
  "component---src-templates-listing-js": () => import("./../../../src/templates/listing.js" /* webpackChunkName: "component---src-templates-listing-js" */),
  "component---src-templates-property-js": () => import("./../../../src/templates/property.js" /* webpackChunkName: "component---src-templates-property-js" */)
}

